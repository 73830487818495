export const zIndex = {
	modal: 10000,
	header: 1000,
	body: 100,
	footer: 10,
}

export const useZIndex = index => {
	if (!(index in zIndex)) {
		return 0
	}
	return zIndex[index]
}

export const breakpoint = {
	max: {
		from: 1531,
		to: 1530,
	},
	preMax: {
		from: 1346,
		to: 1345,
	},
	postDefault: {
		from: 1131,
		to: 1130,
	},
	default: {
		from: 1071,
		to: 1070,
	},
	preMin: {
		from: 756,
		to: 750,
	},
	layout: {
		from: 600,
		to: 599,
	},
	min: {
		from: 626,
		to: 625,
	},
}

export const v = {
	headerHeight: "80px",
	leftSpace: "2rem",
	rightSpace: "2rem",
	minSidePadding: "2rem",
}

export const color = {
	white: {
		default: "#ffffff",
		hover: "#000000",
		pressed: "#ffffff",
	},
	grey: {
		default: "rgba(249, 247, 245, 0.85)",
		hover: "rgba(249, 247, 245, 0.85)",
		pressed: "rgba(249, 247, 245, 0.85)",
	},
	black: {
		default: "#000000",
		hover: "#ffffff",
		pressed: "#000000",
	},
	transparent: {
		default: "transparent",
		hover: "transparent",
		pressed: "transparent",
	},
}

export const shadow = {
	none: {
		default: "none",
		hover: "none",
		pressed: "none",
	},
}

export const padding = {
	base: "0.5rem 1rem;",
}

export const skin = {
	commonButton: {
		border: color.white,
		background: color.black,
		text: color.white,
		shadow: shadow.none,
		padding: padding.base,
	},
}

export default null
