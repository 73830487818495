import React from "react"
import { string, shape } from "prop-types"
import styled from "styled-components"
import A from "./index"

const StyledButton = styled(A)`
	&:before, &:after {
		content: none;
	}
	text-decoration: none;
	border: 1px solid ${props => props.skin.border.default};
	box-shadow: ${props => props.skin.shadow.default};
	color: ${props => props.skin.text.default};
	background-color: ${props => props.skin.background.default};
	padding: ${props => props.skin.padding};
	cursor: pointer;
	transition: color .2s, background-color .2s;
	&:hover, &:focus {
		background-color: ${props => props.skin.background.hover};
		box-shadow: ${props => props.skin.shadow.hover};
		color: ${props => props.skin.text.hover};
	}
`

const Button = ({ skin, ...rest }) => {
	return <StyledButton skin={skin} {...rest} />
}

Button.propTypes = {
	skin: shape({
		color: shape({
			default: string.isRequired,
			hover: string.isRequired,
			pressed: string.isRequired,
		}),
		shadow: shape({
			default: string.isRequired,
			hover: string.isRequired,
			pressed: string.isRequired,
		}),
		border: shape({
			default: string.isRequired,
			hover: string.isRequired,
			pressed: string.isRequired,
		}),
		background: shape({
			default: string.isRequired,
			hover: string.isRequired,
			pressed: string.isRequired,
		}),
	}).isRequired,
}

export default Button
