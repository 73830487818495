import styled from "styled-components"
import {color} from "../../_var"

export const StyledServiceIcon = styled.div`
  padding: 0 2rem 2rem 2rem;
  margin-top: 3rem;
  border: 1px solid rgba(255,255,255,0.85);
  display: flex;
  flex-direction: column;
  .card__image {
    align-self: center;
    position: relative;
    top: -3rem;
    width: 6rem;
    height: 6rem;
    padding: 1.2rem;
    border: 1px solid rgba(255,255,255,0.85);
    background: #272727;
    border-radius: 50%;
  }
  .card__content {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
  }
  .card__headline {
    padding-bottom: 1rem;
  }
`

export const StyledServiceImage = styled.div`
  display: flex;
  margin-bottom: 8rem;
  width: 31%;
  @media (max-width: 880px) {
    width: 100%;
  }
  flex-direction: column;
  justify-content: space-between;
  background-color: ${color.grey.default};
  border: 1px solid ${color.grey.default};
  color: ${color.black.default};
  .card__content {
    padding: 2rem;
    display: flex;
    height: 100%;
    flex-direction: column;
  }
  .card__headline {
    padding-bottom: 1rem;
    font-weight: 500;
  }
`

export default null
