import React, { useRef } from "react"
import { string } from "prop-types"
import styled from "styled-components"
import { sanitized } from "../../_utils"

const StyledHTMLContent = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	& > p,
	& > h1,
	& > h2,
	& > h3,
	& > h4,
	& > h5,
	& > h6 {
		display: inline-block;
		width: 100%
	}
	&.sticky-headlines {
		h2 {
			position: sticky;
			top: 0;
		}
	}
	.gatsby-resp-image-figure {
		margin: 0;
		width: 48%;
		min-width: 250px;
		padding-bottom: 4rem;
		.gatsby-resp-image-figcaption {
			&:before {
				content: "-";
				display: inline-block;
				padding: 0 10px;
			}
		}
	}
	.gatsby-resp-iframe-wrapper {
		border-bottom: 4rem solid transparent;
		width: 100%;
	}
`


const HTMLContent = ({ innerHTML, className, ...rest }) => {
	const elementRef = useRef(null)
	return(
		<StyledHTMLContent
			ref={elementRef}
			className={`HTMLContent ${className}`}
			dangerouslySetInnerHTML={{ __html: sanitized(innerHTML) }}
			{...rest}
		/>
	)}

HTMLContent.propTypes = {
	innerHTML: string,
	className: string,
}

HTMLContent.defaultProps = {
	innerHTML: "",
	className: "",
}

export default HTMLContent
