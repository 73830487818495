import React from "react"
import { string } from "prop-types"
import { StyledServiceImage } from "./style"
import Image from "../Image"
import { imageProps } from "../../_props"
import FromTop from "../IntersectionObserver/FromTop"

const Service = ({ image, headline, content, ...rest }) => {
	return (
		<StyledServiceImage {...rest}>
			{image && (
				<div className="card__image">
					<Image data={image} alt={headline} />
				</div>
			)}
			<div className="card__content">
				<FromTop>
					<h3 className="card__headline">
						{headline}
					</h3>
					{content !== "" && (
						<p className="card__text">
							{content}
						</p>
					)}
				</FromTop>
			</div>
		</StyledServiceImage>
	)
}


Service.propTypes = {
	image: imageProps,
	headline: string.isRequired,
	content: string,
}

Service.defaultProps = {
	image: undefined,
	content: "",
}

export default Service
