import styled from "styled-components"
import { breakpoint } from "../_var"

const StyledLayout = styled.div`
	height: 100%;
	.app {
		min-height: 100%;
		width: 100%;
		position: relative;
		display: flex;
		flex-direction: row;
		@media (min-width: ${breakpoint.layout.from}px) {
			flex-direction: column;
		}
	}
	.app__body {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		flex: 1 100%;
		order: 1;
		overflow: visible;
		width: 100%;
		@media (min-width: ${breakpoint.layout.from}px) {
			order: 2;
		}
	}
	.app__content {
		overflow: visible;
	}
	.content__blog {
		max-width: 800px;
		margin: auto;
	}
`

export default StyledLayout
