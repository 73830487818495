import React from "react"

const iconPaths = {
	linkedIn: {
		path: (
			<path className="linkedIn" d="M134 179c22 0 41.98489-18.0211 41-41-.97422-22.73002-17.08252-40.281815-41-40-22.366.263534-40.499982 18.13247-40.499982 40.5S111.63245 179 134 179zm198 24c-25.99606.45265-46 7-58 20v-17c0-2-2-4-4-4h-60c-2 0-4 2-4 4v207c0 2 2 4 4 4h64c2 0 4-2 4-4V311c0-35 10-50 35-50 28 0 32 25 32 52v100c0 2 2 4 4 4h60c2 0 4-2 4-4V302c0-28.75116-4.53881-49.64094-13.02471-63.3079-7.41647-11.94459-12.09418-18.05327-25.10875-25.3731C366.37932 208.5455 356.6184 202.57134 332 203zm-161-1H99c-2 0-4 2-4 4v207c0 2 2 4 4 4h72c2 0 4-2 4-4V206c0-2-2-4-4-4z" />
		),
		viewBox: undefined,
	},
	facebook: {
		path: (
			<path className="facebook" d="M330 188l-3-1h-45v-20c0-11 7-12 9-12h34c3 0 4-2 4-4V98c0-2-1-4-4-4h-48c-52 0-71 36-71 69v24h-23c-3 0-4 1-4 4v63c0 2 1 4 4 4h27v151c0 2 2 4 4 4h60c2 0 4-2 4-4V258h43c2 0 4-2 4-4l6-63z" />
		),
		viewBox: undefined,
	},
	twitter: {
		path: (
			<path className="twitter" d="M405 127c-1.78472-1.13413-4.09563-2.17192-6-1-11.10427 6.83339-24 12-37 14-12.93883-12.30557-30.14427-19.11606-48-19-40.96933.24198-72.42322 36.39047-67 77-49-4-94-27-124-64l-4-2-3 2c-15.23387 25.84064-11.12591 58.70434 10 80l-13-5c-2.88605-1.71028-6.4687.67815-6 4 0 26 15 49 38 60l-11-1-4 2-1 4c8 23 28 41 53 45-25 16-54 23-84 19-3.392885.50994-4.611351 4.77457-2 7 30 19 64 29 100 29 64 0 118.87447-30.55614 153.87447-81.55614 35.1441-51.02225 27.25142-103.6773 32.16785-107.63634 27.10834-21.82947 29.95374-29.45666 30.47424-31.07744.58359-1.81728 1.06313-3.73955.51794-5.92492-.1867-.74837-2.78275-1.98981-3.4318-1.56587-10.04178 6.5591-22.49336 7.44739-22.49336 7.44739 6.25292-5.12266 13.38309-13.59425 17.22248-24.37821.63912-1.79513 1.39967-5.84363.66818-6.30847z" />
		),
		viewBox: undefined,
	},
	thingiverse: {
		path: (
			<path className="thingiverse" d="M369.14988 125.1144H141.51787c-3 0-4 1-4 4v63c0 2 1 4 4 4h79.89771v201.58475c0 2 2 4 4 4h60c2 0 4-2 4-4V196.1144h79.92286c2 0 4-2 4-4l-.18856-63c-.12092-2.64859-1.15535-3.87624-4-4z" />
		),
		viewBox: undefined,
	},
	gitlab: {
		path: (
			<>
				<path className="gitlab" d="M255.91137 397.08737L94.683135 283.11817l59.819375-173.95764zM255.91137 397.08737L357.10786 109.426l60.96962 173.46691z" />
				<path className="gitlab" d="M255.93792 396.60953L116.1689 220.44482h279.80351z" />
			</>
		),
		viewBox: undefined,
	},
	github: {
		path: (
			<>
				<path className="github" d="M201.82795 329.71829c-10.0368 0-18.2046-10.88635-18.2046-24.2728s8.1678-24.2728 18.2046-24.2728 18.2046 10.88635 18.2046 24.2728-8.16173 24.2728-18.2046 24.2728zm115.29579 0c-10.0368 0-18.2046-10.88635-18.2046-24.2728s8.1678-24.2728 18.2046-24.2728 18.2046 10.88635 18.2046 24.2728-8.16173 24.2728-18.2046 24.2728z" />
				<path className="github" d="M365.10499 326.89657c-4.82421 14.66684-14.7882 24.4063-30.45629 29.78273-16.3538 5.61308-34.3096 8.66539-54.89293 9.32075-5.40677.16991-10.81353.12137-16.2203.085l-7.57918-.0425c-21.3904.30341-46.65232-.31555-71.38023-7.31825-26.19642-7.40927-36.97961-21.22656-39.78311-50.95467-1.88115-19.97651 3.10085-35.10453 15.22511-46.25182 6.87527-6.31092 15.81373-9.64237 26.57871-9.90937 9.56348-.21845 19.39397.13957 28.89677.4976 7.25756.27306 14.5212.54613 21.77876.57647 15.82587.0728 31.83985-.267 47.3441-.59468l8.87777-.18204c5.20045-.10923 10.4009-.32162 15.60134-.52794 5.52813-.22452 11.05626-.44904 16.58439-.54007.21846-.006.43691-.006.65537-.006 25.06166 0 40.09259 24.4063 42.3803 41.08778 1.57167 11.37181.3823 22.81643-3.61058 34.9771z" />
				<path className="github" d="M404.00215 200.1683c-3.61058-4.96986-5.26113-7.89473-4.74533-13.2044 1.07407-11.1837-.45511-22.44021-1.93576-33.32049l-.0971-.71605c-1.97823-14.58795-5.78906-28.5084-11.32932-41.37905l-1.97217-4.57542-8.96273 1.90541c-12.44588 2.6154-25.3226 5.32182-37.05849 12.09999-7.79157 4.50261-15.54673 9.30255-23.04096 13.93866-5.0184 3.10692-10.0368 6.21383-15.08554 9.24187-.27307.16384-.81921.29734-.97091.29734-27.14306-4.73927-55.32985-4.78781-83.80184-.13957-.35195.0607-1.49884-.1335-2.40907-.67357-3.13119-1.87507-6.1835-3.88972-9.22973-5.89829-3.53776-2.33626-7.08159-4.66645-10.72251-6.79638-17.36719-10.19458-36.55484-20.03113-59.18315-22.91959l-4.42979-.57041-2.36053 5.11549c-.44297.9345-.8192 1.74157-1.12868 2.57898-6.49297 17.50069-10.40696 35.85093-11.67522 55.12959-.44298 6.68109-.89809 13.59277.085 20.49231.32768 2.28772.0546 2.7489-.83741 3.86545-8.17387 10.1885-14.017542 20.84426-17.870849 32.57409-6.474769 19.69738-7.645931 41.53683-3.695534 68.71629 3.276828 22.73755 8.853503 39.328 18.065033 53.78245 19.37576 30.38955 49.28591 47.50794 88.90519 50.88186 19.80053 1.68696 39.89234 1.94182 59.58971 1.94182 4.23561 0 8.45301-.0121 12.64613-.0243 5.52813-.0182 11.06233-.0303 16.57832-.0182 25.36508.0121 45.59645-3.28896 63.98917-10.49191 32.744-12.80997 54.5167-36.67013 64.70521-70.92512 5.67983-19.08449 8.22241-39.37655 7.5549-60.32397-.74032-23.33829-7.33038-43.72138-19.57601-60.5849z" />
			</>
		),
		viewBox: undefined,
	},
}
export default iconPaths
