import React from "react"
import { node, string, object } from "prop-types"
import StyledRow from "./style"
import { color as cssColor } from "../../_var"

const Row = ({ children, background, color, style, ...rest }) => {
	return (
		<StyledRow background={background} color={color} {...rest}>
			<div className="in-row" style={style}>{children}</div>
		</StyledRow>
	)
}

export const rowProps = {
	children: node,
	style: object, // eslint-disable-line
	background: string,
	color: string,
}

Row.defaultProps = {
	children: undefined,
	style: {},
	background: "transparent",
	color: cssColor.white.default,
}

Row.propTypes = rowProps

export default Row
