import React from "react"
import Helmet from "react-helmet"
import { arrayOf, object, string } from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { check } from "../../_utils"

const SEO = ({ meta, metaTitle, metaDescription, lang, robots }) => {
	const { site } = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					title
					description
					author
				}
			}
		}
	`)

	const description = check(metaDescription)
		? `${metaDescription}`
		: `${site.siteMetadata.description}`

	const title = check(metaTitle)
		? `${metaTitle}`
		: `${site.siteMetadata.title}`

	return (
		<Helmet
			htmlAttributes={{ lang }}
			title={title}
			meta={[
				{
					name: "description",
					content: description,
				},
				{
					property: "og:title",
					content: title,
				},
				{
					property: "og:description",
					content: description,
				},
				{
					property: "og:type",
					content: "website",
				},
				{
					name: "twitter:card",
					content: "summary",
				},
				{
					name: "twitter:creator",
					content: site.siteMetadata.author,
				},
				{
					name: "twitter:title",
					content: title,
				},
				{
					name: "twitter:description",
					content: description,
				},
			].concat(meta)}
		>
			{robots !== null && robots !== "" && typeof robots !== "undefined" && (
				<meta name="robots" content={robots} />
			)}
		</Helmet>
	)
}

SEO.propTypes = {
	meta: arrayOf(object), // eslint-disable-line
	metaTitle: string,
	metaDescription: string,
	lang: string.isRequired,
	robots: string,
}

SEO.defaultProps = {
	meta: [],
	metaTitle: "",
	metaDescription: "",
	robots: null,
}

export default SEO
