import styled from "styled-components"
import A from "../Link"

const StyledSocialLink = styled(A)`
	&:before, &:after {
		content: none;
	}
	display: block;
	height: 0;
	width: 100%;
	padding-bottom: 100%;
	position: relative;
	overflow: hidden;

	.item__front {
		position: absolute;
		top: 75%;
		bottom: 0;
		left: 0;
		right: 0;
		padding-left: 15%;
		color: #fff;
	}

	.item__svg {
		fill: transparent;
		stroke: white;
		opacity: 0.4;
		stroke-width: 5px;
		transform: translate(25%, -25%);
		transition: opacity 0.6s ease-in-out;
	}

	.item__bg {
		background-color: ${props => props.background};
		path {
			transition: stroke-dashoffset 0.6s ease-in-out;
		}
		path:not(.inner):not(.outer) {
			stroke-dasharray: ${props => props.length}%;
			stroke-dashoffset: 0%;
		}
	}

	.inner {
		stroke-dasharray: 315%;
		stroke-dashoffset: 0%;
	}
	.outer {
		stroke-dasharray: 350%;
		stroke-dashoffset: 0%;
	}
	&:hover, &:focus {
		.item__bg path:not(.inner):not(.outer) {
				stroke-dashoffset: ${props => 2*props.length}%;
		}
		.item__svg {
			opacity: 0.9;
		}
		.inner {
			stroke-dashoffset: 630%;
		}
		.outer {
			stroke-dashoffset: 700%;
		}
		.item__front {
			text-decoration: underline;
		}
	}
`

export default StyledSocialLink
