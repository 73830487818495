import React from "react"
import { string } from "prop-types"
import BackgroundImage from 'gatsby-background-image'
import {StyledMainTheme} from "./style"
import Row from "../Row"
import HTMLContent from "../Content/HTMLContent"
import Fullscreen from "./index"
import { imageProps } from "../../_props"
import FromTop from "../IntersectionObserver/FromTop"

const MainTheme = ({ image, html, ...rest }) => {
	return (
		<StyledMainTheme>
			<BackgroundImage
				fluid={image}
				style={{width: "100%"}}
			>
				<div style={{backgroundColor: "rgba(0,0,0,0.6)"}} {...rest}>
					<Row style={{paddingBottom: "0", paddingTop: "0"}}>
						<Fullscreen>
							<FromTop>
								<HTMLContent innerHTML={html} style={{textAlign: "center"}} />
							</FromTop>
						</Fullscreen>
					</Row>
				</div>
			</BackgroundImage>
		</StyledMainTheme>
	)
}

MainTheme.propTypes = {
	image: imageProps.isRequired,
	html: string.isRequired,
}

export default MainTheme
