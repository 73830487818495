import React from "react"
import useGlobalHook from "use-global-hook"

import * as actions from "../actions"

const initialState = {
	menuClosed: true,
	windowDimensions: {
		width: 0,
		height: 0,
	},
}

const useGlobal = useGlobalHook(React, initialState, actions)

export default useGlobal
