import React, { useState } from "react"
import { arrayOf } from "prop-types"
import { website, filter as propFilter } from "../../_props"
import StyledFilter from "./Filter/style"
import ServicesBlock from "../Services/ServicesBlock"

const Reference = ({ data, filter }) => {
	const [useFilter, setFilter] = useState(filter)
	const [useFocus, setFocus] = useState()
	return (
		<div style={{margin: "auto"}}>
			<div style={{paddingBottom: "6px"}}>
				{useFilter.map((subFilter, subFilterIndex) => (
					<StyledFilter key={subFilter.title}>
						<strong className="filter__headline">{subFilter.headline}</strong>
						{subFilter.content.map((attrFilter, attrFilterIndex) => {
							const { id, checked, label } = attrFilter
							return (
								<label
									htmlFor={id}
									className={`filter__option ${
										useFocus === id ? "focused" : ""
									}`}
									key={id}
								>
									<span className="filter__text">{label}</span>
									<input
										className="filter__input"
										type="checkbox"
										id={id}
										name={id}
										defaultChecked={checked}
										onChange={() => {
											const newFilter = [...useFilter]
											newFilter[subFilterIndex].content[attrFilterIndex] = {
												id,
												label,
												checked: !checked,
											}
											return setFilter(newFilter)
										}}
										onFocus={() => setFocus(id)}
										onBlur={() => setFocus()}
									/>
								</label>
							)
						})}
					</StyledFilter>
				))}
			</div>
			<ServicesBlock
				services={
					data.filter(card => {
						const { filterableProps } = card.fields
						const filtered = useFilter.map(subFilter => {
							const checkedOptionsCount = subFilter.content
								.filter(attrFilter => {
									return attrFilter.checked
								})
								.filter(checkedOption => {
									return checkedOption.id === filterableProps[subFilter.title]
								}).length
							return checkedOptionsCount
						})
						return filtered.indexOf(0) === -1
					})
						.map(card => ({
							image: card.content.image,
							headline: card.content.title,
							content: card.fields.filterableProps.technology.map(item=>(item)).join(", "),
						}
						))
				}
			/>
		</div>
	)
}

Reference.propTypes = {
	data: arrayOf(website).isRequired,
	filter: arrayOf(propFilter).isRequired,
}

export default Reference
