import React from "react"
import { arrayOf, string, shape, number } from "prop-types"
import styled from "styled-components"
import Service from "./index"
import { imageProps } from "../../_props"

const StyledServicesBlock = styled.div`
	width: 85%;
	margin: auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin: auto;
  @media (max-width: 880px) {
    width: 100%;
  }
	.inner{
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		@media (max-width: 880px) {
			flex-direction: column;
			max-width: 40rem;
			margin: auto;
		}
	}
`

const ServicesBlock = ({services}) => {
	return(
		<StyledServicesBlock>
			<div className="inner">
				{services.map(service=>{
					return(
						<Service {...service} key={service.headline} />
					)})}
			</div>
		</StyledServicesBlock>
	)}

ServicesBlock.propTypes = {
	services: arrayOf(
		shape({
			image: imageProps,
			headline: string,
			content: string ,
			order: number,
			button: shape({
				label: string,
				url: string,
			}),
		}),
	),
}

ServicesBlock.defaultProps = {
	services: undefined,
}


export default ServicesBlock
