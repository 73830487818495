import styled from "styled-components"
import { v, breakpoint } from "../../_var"

const StyledRow = styled.div`
	background: ${props => props.background};
	color: ${props => props.color};
	width: 100%;
	.in-row {
		padding: 8rem ${v.leftSpace} 8rem ${v.rightSpace};
		max-width: ${breakpoint.max.to}px;
		width: 100%;
		margin: auto;
		display: flex;
		position: relative;
	}
	& & > .in-row {
		padding-left: 0 !important;
		padding-right: 0 !important;
		padding-top: 0 !important;
	}
	& &:last-child > .in-row {
		padding-bottom: 0 !important;
	}
`

export default StyledRow
