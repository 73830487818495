import styled from "styled-components"
import {breakpoint} from "../../_var"

export const StyledFullscreen = styled.div`
	min-height: 100vh;
	width: 100%;
	transition: min-height .2s .2s;
	display: flex;
	align-items: center;
	justify-content: center;
`

export const StyledMainTheme = styled.div`
	margin-top: 0;
	@media (min-width: ${breakpoint.layout.from}px) {
	margin-top: -70px;
	}
`

export default null
