// resource: https://billyjacoby.com/intersectionobserver-api-with-react-hooks/
import { useState, useEffect } from "react"

const useIntersectionObserver = ( ref, { threshold, root, rootMargin } ) => {
	const [state, setState] = useState({
		inView: false,
		triggered: false,
		entry: undefined,
	})
	
	let observer = {}
	
	if (typeof window !== "undefined"){
		observer = new IntersectionObserver(
			(entries, observerInstance) => {
				// checks to see if the element is intersecting
				if (entries[0].isIntersecting) {
				// if it is update the state, we set triggered as to not re-observe the element
					setState({
						inView: true,
						triggered: true,
						entry: entries[0],
					})
					// unobserve the element
					observerInstance.unobserve(ref.current)
				} else {
					setState({
						inView: false,
						triggered: true,
						entry: entries[0],
					})
				}
			},
			{
				threshold: threshold || 0,
				root: root || null,
				rootMargin: rootMargin || "0%",
			},
		)
	}

	useEffect(() => {
		// check that the element exists, and has not already been triggered
		if (ref.current && !state.triggered) {
			observer.observe(ref.current)
		}
	})
	useEffect(() => {
		return () => {
			observer.unobserve(ref.current)
		}
	},[])


	return state
}

export default useIntersectionObserver