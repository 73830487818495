import React from "react"
import { string } from "prop-types"
import StyledSocialLink from "./style"
import iconPaths from "../SVGIcon/iconPaths"

const SocialLink = ({ strokeDashLength, backgroundColor, iconPath, url, label, ...rest }) => (
	<StyledSocialLink
		href={url}
		background={backgroundColor}
		length={strokeDashLength}
		{...rest}
	>
		<div className="item__bg">
			<svg xmlns="http://www.w3.org/2000/svg" className="item__svg" viewBox="0 0 508 508">
				{iconPaths[iconPath].path}
				<path className="outer" d="M500 379c0 67-54 121-121 121H129C62 500 8 446 8 379V129C8 62 62 8 129 8h250c67 0 121 54 121 121z" />
				<path className="inner" d="M476 138.52593V370c0 58-48 106-106 106H138c-58 0-106-48-106-106V138C32 80 80 32 138 32h231c72.57975-.0026 107.54254 50.852826 107 106.52593z" />
			</svg>
		</div>
		<div className="item__front">
			{label}
		</div>
	</StyledSocialLink>
)

SocialLink.propTypes = {
	strokeDashLength: string.isRequired,
	backgroundColor: string.isRequired,
	url: string.isRequired,
	label: string.isRequired,
	iconPath: string,
}

SocialLink.defaultProps = {
	iconPath: "",
}

export default SocialLink
