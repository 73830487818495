import styled from "styled-components"
// import { color } from "../../../_var"

const StyledFilter = styled.div`
	display: flex;
	width: 100%;
	padding-bottom: 2rem;
	align-items: center;
	flex-wrap: wrap;

	&:last-child {
		padding-bottom: 8rem;
	}

	.filter__headline {
		margin: 0;
		padding: 0;
		&:after {
			content: ":";
			display: inline-block;
			padding-right: 1rem;
		}
	}
	.filter__option {
		display: flex;
		align-items: center;
		white-space: nowrap;
		margin-right: 1rem;
		position: relative;
		cursor: pointer;
		&:after, &:before {
			content: "";
			display: block;
			width: 100%;
			position: absolute;
			bottom: 0;
			left: 0;
			border-bottom: 1px solid silver;
			transition: bottom .2s;
		}
		&:hover, &:focus, &.focused {
			outline: none;
			&:before {
				bottom: 100%;
			}
		}
	}
	.filter__input:focus {
		outline: none;
	}
	.filter__text {
		cursor: pointer;
		user-select: none;
		padding-right: 1rem;
	}
	.filter__input {
		cursor: pointer;
		width: 2rem;
		height: 2rem;
		display: inline-block;
	}
`

export default StyledFilter
