import React from "react"
import { shape, arrayOf, string } from "prop-types"
import StyledFooter from "./style"
import Row from "../Row"
import HTMLContent from "../Content/HTMLContent"
import A from "../Link"

const Footer = ({ data, ...rest }) => {
	return (
		<StyledFooter {...rest}>
			<Row style={{flexWrap: "wrap", paddingBottom: "2rem", paddingTop: "0"}}>
				<Row style={{flexWrap: "wrap", justifyContent: "space-between", paddingBottom: "4rem"}}>
					{data.content.map(item=>(
						<HTMLContent innerHTML={item} key={`${item}StyledFooter`.substring(1, 10).replace(/\s+/g, "-")} />
					))}
				</Row>
				<Row style={{justifyContent: "space-between", paddingBottom: "4rem"}}>
					<span>Tomáš Pilnaj</span>
					<A href={data.under}>repo</A>
				</Row>
			</Row>
		</StyledFooter>
	)
}


Footer.propTypes = {
	data: shape({
		content: arrayOf(string),
		under: string,
	}),
}
Footer.defaultProps = {
	data: {
		content: [],
		under: "",
	},
}

export default Footer
