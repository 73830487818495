import { sanitize } from "dompurify"

export const fixSlash = string => (string.length === 0 ? "" : `/${string}`)
export const checkSlash = string => (string.length === 0 ? "/" : `${string}/`)
export const LANG_INFO = {
	// http://www.lingoes.net/en/translator/langcode.htm
	cs: {
		label: "česky",
		url: "",
		switchLang: "en",
	},
	en: {
		label: "english",
		url: "en",
		switchLang: "cs",
	},
}

const config = {
	ALLOWED_TAGS: [
		"p",
		"strong",
		"em",
		"code",
		"a",
		"h1",
		"h2",
		"blockquote",
		"pre",
		"code",
		"ul",
		"ol",
		"li",
		"div",
		"iframe",
		"img",
	],
	ALLOWED_ATTR: [
		"style",
		"width",
		"height",
		"src",
		"frameborder",
		"allow",
		"allowfullscreen",
		"href",
	],
}

export const richTextMarkdown = html => {
	const sanitized = sanitize(html, config)
	return sanitized
}

export const purify = html => {
	const sanitized = sanitize(html)
	return sanitized
}

export const sanitized = html => {
	return html
}

export const cleanString = val => {
	return String(val)
		.replace(/\s+/g, "-")
		.toLowerCase()
}

export const check = val => {
	return !(typeof val === "undefined" || val === null || val === "")
}

export default null
