// https://www.freecodecamp.org/news/react-pattern-centralized-proptypes-f981ff672f3b/ 
import { string, arrayOf, shape, bool, oneOfType, number } from "prop-types"

export const imageProps = oneOfType([
	string,
	shape({
		childImageSharp: shape({
			fluid: shape({
				aspectRatio: number,
				sizes: string,
				src: string,
				srcSet: string,
				srcSetWebp: string,
				srcWebp: string,
			}).isRequired,
		}),
	}),
	shape({
		childImageSharp: shape({
			fixed: shape({
				aspectRatio: number,
				sizes: string,
				src: string,
				srcSet: string,
				srcSetWebp: string,
				srcWebp: string,
			}).isRequired,
		}),
	}),
])

export const filter = shape({
	title: string.isRequired,
	content: arrayOf(
		shape({
			id: string.isRequired,
			checked: bool.isRequired,
		}).isRequired,
	).isRequired,
})

export const website = shape({
	fields: shape({
		filterableProps: shape({
			company: string.isRequired,
			age: string.isRequired,
			influence: string.isRequired,
			technology: arrayOf(string).isRequired,
		}).isRequired,
	}).isRequired,
	content: shape({
		title: string.isRequired,
		body: string.isRequired,
		links: arrayOf(
			shape({
				href: string,
			}).isRequired,
		).isRequired,
	}).isRequired,
})

export const menu = shape({
	menu: arrayOf(
		shape({
			nodes: shape({
				frontmatter: shape({
					menuTitle: string.isRequired,
					url: string.isRequired,
				}).isRequired,
			}).isRequired,
		}).isRequired,
	),
})

export const socialProps = shape({
	strokeDashLength: string.isRequired,
	backgroundColor: string.isRequired,
	url: string.isRequired,
	label: string.isRequired,
	iconPath: string,
})

export const servicesProps = shape({
	services: shape({
		nodes: arrayOf(
			shape({
				id: string,
				image: imageProps,
				headline: string,
				content: string,
			}),
		),
	}),
})

export default null
