import React, { useState } from "react"
import { useTransition, animated } from "react-spring"
import { node } from "prop-types"

const AnimatedContent = ({ children }) => {
	const [show, _set] = useState(true)
	const transitions = useTransition(show, null, {
		from: { opacity: 0, transform: "translateY(-5px)" },
		enter: { opacity: 1, transform: "translateY(0)" },
	})
	return (
		<>
			{transitions.map(({ item, key, props }) => {
				return (
					item && (
						<animated.div
							key={key}
							style={{
								...props,
								width: "100%",
								transformOrigin: "top center",
							}}
						>
							{children}
						</animated.div>
					)
				)
			})}
		</>
	)
}

AnimatedContent.propTypes = {
	children: node.isRequired,
}

export default AnimatedContent
