import React from "react"
import { arrayOf, string, shape } from "prop-types"
import Menu from "../Menu"
import Row from "../Row"
import MenuOpener from "../Menu/MenuOpener"
import StyledHeader from "./style"

const Header = ({ menu, lang, ...rest }) => {
	return (
		<StyledHeader role="navigation" {...rest}>
			<MenuOpener />
			<Row style={{paddingBottom: "2rem", paddingTop: "2rem"}}>
				<Menu menu={menu} lang={lang} />
			</Row>
		</StyledHeader>
	)
}

Header.propTypes = {
	menu: shape({
		pages: arrayOf(
			shape({
				frontmatter: shape({
					menuTitle: string.isRequired,
					url: string.isRequired,
				}).isRequired,
			}).isRequired,
		),
		switchUrl: shape({
			url: string.isRequired,
			label: string.isRequired,
		}).isRequired,
	}).isRequired,
	lang: string.isRequired,
}

export default Header
