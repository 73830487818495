import React from "react"
import styled from "styled-components"
import useGlobal from "../../store"
import { useZIndex, breakpoint, skin } from "../../_var"
import Button from "../Link/Button"

const StyledMenuOpener = styled.div`
	margin: 2rem 2rem 0 0;
	position: absolute;
	top: 0;
	right: 0;
	z-index: ${useZIndex("header")};
	white-space: nowrap;

	@media (min-width: ${breakpoint.layout.from}px) {
		display: none;
	}
	@media print {
		display: none;
	}
`

const MenuOpener = () => {
	const [globalState, globalActions] = useGlobal()
	const click = () => {
		if (globalState.windowDimensions.width < breakpoint.layout.from) {
			globalActions.menuAction()
		}
	}

	return (
		<StyledMenuOpener
			tabIndex="0"
			onClick={click}
		>
			<Button 
				skin={skin.commonButton}
			>
				{globalState.menuClosed ? (
					<span className="arial">☰</span>
				) : (
					<span className="arial">🗙</span>
				)}
				&nbsp;menu
			</Button>
		</StyledMenuOpener>
	)
}
export default MenuOpener
