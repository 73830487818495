import React, { useEffect } from "react"
import { arrayOf, shape, string, node } from "prop-types"
import { useSpring, animated } from "react-spring"
import Header from "../component/Header"
import Footer from "../component/Footer"
// import CookieBanner from "../CookieBanner";
import SEO from "../component/SEO"
import StyledLayout from "./style"
import useGlobal from "../store"
import GlobalStyle from "../_css"
import { breakpoint } from "../_var"

const Layout = ({ children, menu, lang, seo, footer }) => {
	const { metaTitle, metaDescription, index, follow } = seo
	const [globalState, globalActions] = useGlobal()
	const menuVisible =
		!globalState.menuClosed &&
		globalState.windowDimensions.width < breakpoint.layout.from
	const { transform } = useSpring({
		transform: `translateX(${menuVisible ? -200 : 0}px)`,
	})

	useEffect(() => {
		function handleResize() {
			globalActions.viewportDimensionAction()
		}
		handleResize()
		window.addEventListener("resize", handleResize)
		return () => window.removeEventListener("resize", handleResize)
	}, []) // Empty array ensures that effect is only run on mount and unmount

	return (
		<>
			<GlobalStyle />
			<StyledLayout>
				<SEO
					title={metaTitle}
					description={metaDescription}
					lang={lang}
					index={index}
					follow={follow}
				/>
				<animated.div style={{ transform }} className="app">
					<Header className="app__header" menu={menu} lang={lang} />
					<div className="app__body">
						<div className="app__content" role="main">{children}</div>
						<Footer data={footer} role="contentinfo" />
					</div>
				</animated.div>
				{/* <CookieBanner className="app__cookie" lang={lang} /> */}
			</StyledLayout>
		</>
	)
}

Layout.propTypes = {
	children: node.isRequired,
	lang: string,
	footer: shape({
		content: arrayOf(string),
		under: string,
	}),
	seo: shape({
		metaTitle: string,
		metaDescription: string,
	}),
	menu: shape({
		pages: arrayOf(
			shape({
				frontmatter: shape({
					menuTitle: string.isRequired,
					url: string.isRequired,
				}).isRequired,
			}).isRequired,
		),
		switchUrl: shape({
			url: string.isRequired,
			label: string.isRequired,
		}).isRequired,
	}),
}

Layout.defaultProps = {
	lang: "cs",
	menu: {
		pages: [
			{
				frontmatter: {
					menuTitle: "",
					url: "",
				},
			},
		],
		switchUrl: {
			url: "en",
			label: "english",
		},
	},
	footer: {
		content: [],
		under: "",
	},
	seo: {
		metaTitle: "",
		metaDescription: "",
	},
}

export default Layout
