import React from "react"
import { arrayOf } from "prop-types"
import styled from "styled-components"
import {socialProps} from "../../_props"
import SocialLink from "./index"

const StyledSocialBlock = styled.div`
	width: 100%;
	margin: 0 auto;
	.inner{
		display: flex;
		flex-wrap: wrap;
		margin: -1rem;
		justify-content: space-around;
	}
	.social {
		min-width: 12rem;
		max-width: 50%;
		margin: 1rem;
		flex: 1;
	}
	@media (max-width: 880px) {
		max-width: 53rem;
	}
`

const SocialBlock = ({socials}) => {
	return(
		<StyledSocialBlock>
			<div className="inner">
				{socials.map(social=>{
					return(
						<div className="social" key={social.id}>
							<SocialLink {...social} />
						</div>
					)})}
			</div>
		</StyledSocialBlock>
	)}

SocialBlock.propTypes = {
	socials: arrayOf(socialProps).isRequired,
}


export default SocialBlock
