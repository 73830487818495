import React from "react"
import { string, shape, arrayOf } from "prop-types"
import styled from "styled-components"
import Link from "../Link"
import { LANG_INFO, fixSlash, checkSlash } from "../../_utils"
import useGlobal from "../../store"
import { breakpoint } from "../../_var"

const StyledMenu = styled.nav`
	display: flex;
	width: 100%;
	flex-direction: column;
	.link {
		display: block;
		background-color: transparent;
		margin: 1rem 1.5rem 1rem 0rem;
	}
	.link__active {
		&:before, &:after{
			content: none;
		}
	}
	@media (min-width: ${breakpoint.layout.from}px) {
		flex-direction: row;
		.link {
			margin: 0 1rem;
			padding: 0 1rem;
		}
		.link:first-child {
			margin-left: -1rem;
		}
		.link:last-child {
			margin-left: auto;
		}
	}
`

const Menu = ({ menu, lang }) => {
	const [globalState, globalActions] = useGlobal()
	const click = () => {
		if (globalState.windowDimensions.width < breakpoint.layout.from) {
			globalActions.menuAction()
		}
	}
	const tabIndexSettings =
		globalState.menuClosed &&
		globalState.windowDimensions.width < breakpoint.layout.from

	return (
		<StyledMenu id="nav">
			{menu.pages.map(page => {
				const {
					frontmatter: { url, menuTitle },
				} = page
				const fullUrl = checkSlash(
					`${fixSlash(LANG_INFO[lang].url)}${fixSlash(url)}`,
				)
				return (
					<Link
						onClick={click}
						className="link"
						key={`${url}-li`}
						href={fullUrl}
						getProps={({ isCurrent }) => {
							return {
								tabIndex: isCurrent || tabIndexSettings ? -1 : 0,
								className: isCurrent ? "link link__active" : "link",
							}
						}}
					>
						{menuTitle}
					</Link>
				)
			})}
			{typeof menu.switchUrl !== "undefined" ? (
				<Link
					tabIndex={tabIndexSettings ? "-1" : "0"}
					className="link"
					onClick={click}
					href={menu.switchUrl.url}
				>
					{menu.switchUrl.label}
				</Link>
			) : null}
		</StyledMenu>
	)
}

Menu.propTypes = {
	menu: shape({
		pages: arrayOf(
			shape({
				node: shape({
					frontmatter: shape({
						menuTitle: string,
						url: string,
					}),
				}),
			}),
		),
		switchUrl: shape({
			url: string,
			label: string,
		}),
	}),
	lang: string,
}

Menu.defaultProps = {
	menu: {
		pages: [
			{
				frontmatter: {
					menuTitle: "",
					url: "",
				},
			},
		],
		switchUrl: {
			url: "en",
			label: "english",
		},
	},
	lang: "cs",
}

export default Menu
