import React from "react"
import { graphql, Link } from "gatsby"
import { string, shape, arrayOf } from "prop-types"
import { website, filter, menu, imageProps, socialProps, servicesProps } from "../_props"
import Layout from "../layouts"
import HTMLContent from "../component/Content/HTMLContent"
import SimpleContent from "../component/Content/Simple"
import AnimatedContent from "../component/Content/AnimatedContent"
// import ContactForm from "../component/ContactForm"
import Reference from "../component/Reference"
import Row from "../component/Row"
import SocialBlock from "../component/SocialLink/SocialBlock"
import ServicesBlock from "../component/Services/ServicesBlock"
import MainTheme from "../component/Fullscreen/MainTheme"
import Fullscreen from "../component/Fullscreen"
import {color} from "../_var"
import { LANG_INFO, fixSlash, checkSlash } from "../_utils"

const Index = ({ data, pageContext }) => {
	const {
		metaTitle,
		metaDescription,
		lang,
		switchUrl,
		html,
		template,
		index,
		follow,
	} = pageContext
	const content = {
		index: (
			<AnimatedContent key={`index-${lang}`}>
				<div className="content__index">
					<MainTheme
						image={data.backgroundImages.web.childImageSharp.fluid}
						html={pageContext.html} 
					/>
					<Fullscreen style={{flexDirection: "column", backgroundColor: color.white.default}}>
						<Row background={color.white.default} color={color.black.default} style={{paddingBottom: "0"}}>
							<SimpleContent
								headline={data.servicesIntro.headline}
								content={data.servicesIntro.content}
							/>
						</Row>
						<Row background={color.white.default} color={color.black.default} style={{paddingBottom: "0"}}>
							<ServicesBlock
								services={data.services.nodes}
							/>
						</Row>
					</Fullscreen>
					{/* <Row>
						<SimpleContent
							headline={data.servicesIntro.headlineMinor}
							content={data.servicesIntro.contentMinor}
						/>
					</Row> */}
					<Row background={color.black.default} color={color.white.default} style={{paddingBottom: "0"}}>
						<SimpleContent
							headline={data.socialIntro.headline}
						/>
					</Row>
					<Row background={color.black.default} color={color.white.default}>
						<SocialBlock
							socials={data.socials.nodes}
						/>
					</Row>
				</div>
			</AnimatedContent>
		),
		blog: (
			<AnimatedContent key={`blog-${lang}`}>
				<div className="content__blog">
					<Row background={color.white.default} color={color.black.default}>
						<HTMLContent innerHTML={html} />
					</Row>
					<Row background={color.white.default} color={color.black.default}>
						<div className="blog__posts" style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
							{data.blogPosts.nodes.map(
								(post)=>{
									const {url, title} = post.frontmatter
									const fullUrl = checkSlash(
										`${fixSlash(LANG_INFO[lang].url)}${fixSlash(url)}`,
									)
									return (
										<Link to={fullUrl}>
											{title}
										</Link>
									)},
							)}
						</div>
					</Row>
				</div>
			</AnimatedContent>
		),
		blogPost: (
			<AnimatedContent key={`blog-post-${lang}`}>
				<div className="content__blog">
					<Row
						background={color.white.default}
						color={color.black.default}
						style={{
							padding: "2rem",
							margin: "2rem 0",
						}}
					>
						<HTMLContent innerHTML={html} />
					</Row>
					<Row
						background={color.white.default}
						color={color.black.default}
						style={{
							padding: "2rem",
						}}
					>
						<div className="blog__posts" style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
							{data.blogPosts.nodes.map(
								(post)=>{
									const {url, title} = post.frontmatter
									const fullUrl = checkSlash(
										`${fixSlash(LANG_INFO[lang].url)}${fixSlash(url)}`,
									)
									return (
										<Link to={fullUrl}>
											{title}
										</Link>
									)},
							)}
						</div>
					</Row>
				</div>
			</AnimatedContent>
		),
		preview: (
			<AnimatedContent key={`preview-${lang}`}>
				<div className="content__preview">
					<Row>
						<HTMLContent innerHTML={html} />
					</Row>
				</div>
			</AnimatedContent>
		),
		contact: (
			<AnimatedContent key={`contact-${lang}`}>
				<div className="content__contact">
					<MainTheme
						image={data.backgroundImages.web.childImageSharp.fluid}
						html={pageContext.html} 
					/>
					{/* <ContactForm data={data.contact} /> */}
					<Row background={color.white.default} color={color.black.default} style={{paddingBottom: "0"}}>
						<SimpleContent headline={data.socialIntro.headline} />
					</Row>
					<Row background={color.white.default} color={color.black.default}>
						<SocialBlock
							socials={data.socials.nodes}
						/>
					</Row>
				</div>
			</AnimatedContent>
		),
		reference: (
			<AnimatedContent key={`reference-${lang}`}>
				<div className="content__reference">
					<Row>
						<div style={{textAlign: "center", margin: "auto"}}>
							<HTMLContent innerHTML={html} />
						</div>
					</Row>
					<Row background={color.white.default} color={color.black.default} style={{paddingBottom: "0"}}>
						<Reference
							data={data.reference.nodes}
							filter={data.referenceFilter.nodes}
						/>
					</Row>
				</div>
			</AnimatedContent>
		),
	}
	return (
		<Layout
			lang={lang}
			seo={{ metaTitle, metaDescription, index, follow }}
			menu={{ pages: data.menu.nodes, switchUrl }}
			footer={{
				content: [
					data.footerLeft.html,
					data.footerRight.html,
				],
				under: pageContext.repo,
			}}
		>
			{content[template]}
		</Layout>
	)
}

Index.propTypes = {
	data: shape({
		menu,
		reference: shape({
			nodes: arrayOf(website),
		}),
		referenceFilter: shape({
			nodes: arrayOf(filter),
		}),
		footerLeft: shape({
			html: string.isRequired,
		}),
		footerRight: shape({
			html: string.isRequired,
		}),
		socials: shape({
			nodes: arrayOf(socialProps),
		}),
		socialIntro: shape({
			headline: string,
			content: string,
		}),
		services: shape({
			nodes: arrayOf(servicesProps),
		}),
		servicesMinor: shape({
			nodes: arrayOf(servicesProps),
		}),
		servicesIntro: shape({
			headline: string,
			content: string,
			headlineMinor: string,
			contentMinor: string,
		}),
		backgroundImages: shape({
			web: imageProps,
		}),
	}).isRequired,
	pageContext: shape({
		metaTitle: string,
		template: string,
		metaDescription: string,
		lang: string,
		switchUrl: shape({
			url: string.isRequired,
			label: string.isRequired,
		}),
		html: string,
	}).isRequired,
}

export default Index

export const query = graphql`
	query IndexQuery($lang: String!) {
		menu: allMarkdownRemark(
			filter: { frontmatter: { inMenu: { eq: true }, lang: { eq: $lang } } }
			sort: { fields: frontmatter___menuOrder }
		) {
			nodes {
				frontmatter {
					menuTitle
					url
				}
			}
		}
		blogPosts: allMarkdownRemark(
			filter: { frontmatter: { template: {eq: "blogPost"}, lang: { eq: $lang } } }
		) {
			nodes {
				frontmatter {
					title
					url
				}
			}
		}
		footerLeft: markdownRemark(
			frontmatter: { type: { eq: "footer-fragment-left" }, lang: { eq: $lang } }
		) {
			html
		}
		footerRight: markdownRemark(
			frontmatter: {
				type: { eq: "footer-fragment-right" }
				lang: { eq: $lang }
			}
		) {
			html
		}
		contact: formJson(lang: { eq: $lang }) {
			form {
				fieldset {
					headline
					id
					input {
						field
						options {
							label
							value
							disabled
						}
						type
						required
						validation
						initialValue
						name
						label
						placeholder
						requiredMessage
						validationMessage
						visualStyle
					}
				}
				submit {
					label
					success
					error
					submitting
					reCaptchaError
				}
				renderError {
					errorHeadline
					errorParagraph
				}
			}
		}
		reference: allWebsitesJson {
			nodes {
				id
				title
				fields {
					filterableProps {
						company
						age
						influence
						technology
					}
				}
				hide
				content {
					image {
						extension
						publicURL
						childImageSharp {
							fluid {
								...GatsbyImageSharpFluid_withWebp
							}
						}
					}
					title
					body
					links {
						href
						label
					}
				}
			}
		}
		referenceFilter: allFilterJson(filter: { lang: { eq: $lang } }) {
			nodes {
				title
				headline
				content {
					id
					checked
					label
				}
			}
		}
		socials: allSocialJson(
			filter: { type: { eq: "social" } }
			sort: { fields: order, order: ASC }
		) {
			nodes {
				id
				url
				label
				iconPath
				strokeDashLength
				backgroundColor
			}
		}
		socialIntro: socialJson(
			lang: { eq: $lang },
			type: { eq: "social-intro" }
		) {
			headline
		}
		backgroundImages: systemJson(
			title: { eq: "background-images" }
		) {
			web {
				extension
				publicURL
				childImageSharp {
					fluid(maxWidth: 2400) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
		}
		services: allServicesJson(
			filter: { lang: { eq: $lang }, type: { eq: "service" } }
			sort: { fields: order, order: ASC }
		) {
			nodes {
				id
				image {
					extension
					publicURL
					childImageSharp {
						fluid(maxWidth: 500) {
							...GatsbyImageSharpFluid
						}
					}
				}
				headline
				content
			}
		}
		servicesMinor: allServicesJson(
			filter: { lang: { eq: $lang }, type: { eq: "minor-service" } }
			sort: { fields: order, order: ASC }
		) {
			nodes {
				id
				headline
				content
			}
		}
		servicesIntro: servicesJson(
			lang: { eq: $lang },
			type: { eq: "services-intro" }
		) {
			headline
			content
			headlineMinor
			contentMinor
		}
	}
`
