import React, { useRef } from "react"
import { node } from "prop-types"
import styled from "styled-components"
import useIntersectionObserver from "./index"


const StyledFromTop = styled.div`
	width: 100%;
  opacity: ${props => props.observerState.inView ? 1 : .5};
  transform: translateY(${props => props.observerState.inView ? "0" : "-2rem"});
  transition: opacity .75s, transform .75s;
`
const FromTop = ({ children, ...rest }) => {
	const elementRef = useRef(null)
	const observerState = useIntersectionObserver(elementRef, {threshold: 0/* .2 */})

	return(
		<StyledFromTop {...rest} observerState={observerState} ref={elementRef}>
			{children}
		</StyledFromTop>
	)}

FromTop.propTypes = {
	children: node.isRequired,
}

export default FromTop
