/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// require("prismjs/themes/prism-okaidia.css")

exports.onClientEntry = async () => {
	// NOTE: Don't polyfill Promise here (Gatsby already includes a Promise polyfill)

	// IntersectionObserver polyfill for gatsby-image (Safari, IE)
	if (typeof window.IntersectionObserver === `undefined`) {
		await import(`intersection-observer`)
		alert("now") // eslint-disable-line
	}
}

// https://www.gatsbyjs.org/packages/gatsby-plugin-transitions/#2-disable-default-scroll-to-top
exports.shouldUpdateScroll = () => {
	return false
}
