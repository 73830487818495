import React from "react"
import { string } from "prop-types"
import styled from "styled-components"

const StyledSimpleContent  = styled.div`
	text-align: center;
	margin: auto;
`

const SimpleContent = ({ headline, content, ...rest }) => {
	return(
		<StyledSimpleContent {...rest}>
			<h2>{headline}</h2>
			<p>{content}</p>
		</StyledSimpleContent>
	)}

SimpleContent.propTypes = {
	headline: string,
	content: string,
}

SimpleContent.defaultProps = {
	headline: "",
	content: "",
}

export default SimpleContent
