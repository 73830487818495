module.exports = [{
      plugin: require('../node_modules/gatsby-background-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-firebase/gatsby-browser.js'),
      options: {"plugins":[],"features":{"database":true,"firestore":true},"credentials":{"apiKey":"AIzaSyDqP7kzzpzzvS4RRACoFgnWVI1dNbZILKE","authDomain":"tomas-pilnaj-cz.firebaseapp.com","databaseURL":"https://tomas-pilnaj-cz.firebaseio.com","projectId":"tomas-pilnaj-cz","storageBucket":"tomas-pilnaj-cz.appspot.com","messagingSenderId":"79162015082","appId":"1:79162015082:web:fae0bc7eb19b4cd7fcb913","measurementId":"G-ZS3WC49ELN"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"./src/image/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"903c8c7fae84d5759d1d6d5f6f14b718"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-M684KB","includeInDevelopment":false},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":800,"backgroundColor":"transparent","linkImagesToOriginal":false,"showCaptions":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/builds/pilniczek-websites/advanced-web/src/template/Index.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"enableIdentityWidget":false,"modulePath":"/builds/pilniczek-websites/advanced-web/cms/cms.js","manualInit":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://48a9aa79871f439d9929ae9bdd845ae0@sentry.io/1377258","environment":"production","enabled":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
