import styled from "styled-components"

const StyledFooter = styled.div`
	padding-top: 4rem;
	.HTMLContent {
		&:not(:last-child) {
			padding-right: 2rem;
		}
	}
	p {
		padding: 0;
	}
	.gatsby-resp-image-wrapper {
		width: 105px;
		margin: 0 !important;
	}
`

export default StyledFooter
