import React from "react"
import { node, object } from "prop-types"
import {StyledFullscreen} from "./style"

const FullScreen = ({ children, ...rest }) => {
	return (
		<StyledFullscreen {...rest}>
			{children}
		</StyledFullscreen>
	)
}

FullScreen.defaultProps = {
	children: undefined,
	style: {},
}

FullScreen.propTypes = {
	children: node,
	style: object, // eslint-disable-line
}

export default FullScreen
