import styled from "styled-components"
import { breakpoint, useZIndex } from "../../_var"

const StyledHeader = styled.div`
width: 200px;
margin-right: -400px;
padding-right: 200px;
box-sizing: content-box;
order: 2;
background-color: #000000;
z-index: ${useZIndex("header")};
@media (min-width: ${breakpoint.layout.from}px) {
	order: 1;
	max-width: none;
	width: auto;
	background-color: transparent;
	margin-right: 0px;
	padding-right: 0px;
}
`

export default StyledHeader
